import { useCallback, useState } from "react";
import type { TorrentSearchResult } from "./useTorrentSearch";



type StreamCallback = () => void;
export default function useTorrentStreamer(result: TorrentSearchResult): [string | null, string | null, StreamCallback] {
    const [status, setStatus] = useState<string | null>(null);
    const [url, setUrl] = useState<string | null>(null);

    const stream = useCallback(async () => {

        setStatus('Getting magnet...');

        // 1. Get magnet
        const magnetResponse = await fetch('https://search.tt.kolhos.chichasov.es/dl/magnet', {
            method: 'POST',
            body: JSON.stringify(result),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const magnetJson = await magnetResponse.json();

        setStatus('Adding magnet...');

        // 2. Add magnet
        const addMagnetResponse = await fetch('https://tt.kolhos.chichasov.es/magnet', {
            method: 'POST',
            body: JSON.stringify(magnetJson),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const hash = await addMagnetResponse.text();

        setStatus('Awaiting status...');

        // 3. Wait until got status
        let files = null;
        while (true) {
            const statusResp = await fetch(`https://tt.kolhos.chichasov.es/info/${hash}`);
            const status = await statusResp.json();
            if (status.ready) {
                files = status.files;
                break;
            }

            await new Promise(r => setTimeout(r, 1500));
        }

        if (files == null) {
            // fail
            setStatus('failed');
            return;
        }

        setStatus('Starting stream...');

        // 4. Start stream
        await fetch(`https://tt.kolhos.chichasov.es/stream/${hash}`, {
            method: 'POST',
            body: JSON.stringify({
                file: files.find((a: string) => a.indexOf('.mp4') !== -1 || a.indexOf('.mkv') !== -1)
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const ext = files.some((a: string) => a.indexOf('.mp4') !== -1) ? 'mp4' : 'mkv';

        console.log(`Link is: https://tt.kolhos.chichasov.es/watch/${hash}/video.${ext}`);
        setUrl(`https://tt.kolhos.chichasov.es/watch/${hash}/video.${ext}`);
        setStatus('Success');
    }, [result]);

    return [url, status, stream];
}