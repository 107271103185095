import { useCallback, useState } from "react";

export type TorrentSearchResult = {
    title: string,
    time: string,
    seeds: number,
    peers: number,
    size: string,
    desc: string,
    provider: string
};

export type MediaType = 'movies' | 'tv';

type SearchCallback = (query: string, category: MediaType) => void;

export default function useTorrentSearch(): [
    Array<TorrentSearchResult> | null,
    boolean,
    SearchCallback
] {
    const [results, setResults] = useState<Array<TorrentSearchResult> | null>(null);
    const [loading, setLoading] = useState(false);

    const load = useCallback(async (query: string, category: MediaType) => {
        const params = {
            query,
            category,
            limit: '10',
        };

        const url = 'https://search.tt.kolhos.chichasov.es/dl/search?' + 
            new URLSearchParams(params).toString();

        setLoading(true);
        const result = await fetch(url);
        const blob = await result.json();

        setResults(blob);
        setLoading(false);
    }, []);

    return [results, loading, load];
}