import React, { useCallback, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import useTorrentSearch, { MediaType } from './hooks/useTorrentSearch';
import SearchResult from './components/SearchResult';
import { CircularProgress, Container, Grid } from '@mui/material';
import SearchBox from './components/SearchBox';

function App() {
  const [searchResults, isLoading, doSearch] = useTorrentSearch();
  const search = useCallback((query: string, type: MediaType) => {
    doSearch(query, type);
  }, []);


  return (
    <div className={css(styles.container)}>
      <Container>
        <Grid container>
          <Grid item sm={3} xs={0}></Grid>
          <Grid item xs={12} sm={6}>
            <SearchBox onSearch={search} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={3} xs={0}></Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? <div className={css(styles.loader)}><CircularProgress /></div>:
              searchResults?.map(r => <SearchResult result={r} />)
            }
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
    marginBottom: 12,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 20,
  },
});

export default App;
