import type { TorrentSearchResult } from "../hooks/useTorrentSearch";
import useTorrentStreamer from "../hooks/useTorrentStreamer";
import { StyleSheet, css } from 'aphrodite';
import { Box, Button, Card, CardContent, Grid, Icon, Snackbar, Typography } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useEffect, useState } from "react";
import usePrevious from "../hooks/usePrevious";

type Props = {
    result: TorrentSearchResult,
};

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '4px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


export default function SearchResult(props: Props) {
    const { result } = props;

    const [url, status, stream] = useTorrentStreamer(result);
    const [snackOpen, setSnackOpen] = useState(false);

    const prevUrl = usePrevious(url);

    useEffect(() => {
        if (prevUrl == null && url != null) {
            setSnackOpen(true);
            navigator.clipboard.writeText(url).catch(e => console.error(e));
        }
    }, [url]);

    return (
        <Card style={{ marginTop: 20 }} variant="outlined">
            <CardContent>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={9}>
                        <Typography color="text.secondary" gutterBottom>
                            {result.provider}{bull}{result.time}
                        </Typography>
                        <Typography variant="h5">
                            {result.title}
                        </Typography>
                        <Typography color="text.secondary">
                            {result.seeds}
                            <Icon fontSize="inherit">
                                <ArrowUpward fontSize="inherit" />
                            </Icon>
                            {bull}
                            {result.peers}
                            <Icon fontSize="inherit">
                                <ArrowDownward fontSize="inherit" />
                            </Icon>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button variant="outlined" onClick={stream} disabled={status != null} >
                            {status != null ? status : 'Stream'}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackOpen}
                onClose={() => setSnackOpen(false)}
                message="Link copied to clipboard!"
                />
        </Card>
    )
}


const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid black',
        width: 'fit-content',
        margin: 16,
        padding: 16,
    }
});
