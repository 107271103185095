import { IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import type { MediaType } from "../hooks/useTorrentSearch"
import Search from '@mui/icons-material/Search';

type Props = {
    onSearch: (query: string, type: MediaType) => void;
}

export default function SearchBox(props: Props) {
    const { onSearch } = props;
    const [query, setQuery] = useState('');
    const [category, setCategory] = useState<MediaType>('movies');

    return (
        <div style={{ display: 'flex' }}>
            <Select value={category} onChange={e => setCategory(e.target.value === 'movies' ? 'movies' : 'tv')}>
                <MenuItem value="movies">Movies</MenuItem>
                <MenuItem value="tv">TV Shows</MenuItem>
            </Select>
            <TextField 
                label="Search"
                variant="outlined"
                fullWidth
                onChange={(e) => setQuery(e.target.value)}
                onSubmit={() => onSearch(query, category)}
                value={query}
                InputProps={{
                    endAdornment: (<IconButton onClick={() => onSearch(query, category)}><Search /></IconButton>)
                }}
            />
        </div>
    )
}