import { useEffect, useRef } from "react";

export default function usePrevious<T>(value: T): T | undefined {
    const val = useRef(value);

    useEffect(() => {
        val.current = value;
    }, [value]);

    return val.current;
}